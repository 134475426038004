
body {
	width: 100%;
	height: 100%;
	margin: 0;
	padding: 0;
}

#title {

	background: url(./images/background.jpg);
	background-size: cover;
	background-attachment: fixed;
	background-position-x: center;
	background-position-y: center;
	margin: 0;
	padding: 0;

	color: white;
	width: 100%;
	height: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
	font-family: Arial, Helvetica, sans-serif;

	#content {
		background-color: rgba(0, 0, 0, 0.5);
		width: 100%;
		display: flex;
		justify-content: center;
		align-items: center;

		#text {
			font-family: Arial, Helvetica, sans-serif;
			text-align: center;
			max-width: 500px;
			padding: 10px;

			p {
				line-height: 1.5;
			}
		}
	}
}


